import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ guidelines for the construction of passwords.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Passwords are a critical component of information security. Passwords
serve to protect user accounts; however, a poorly constructed password
may result in the compromise of individual systems, data, or network.
This guideline provides best practices for creating secure passwords.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this guidelines is to provide best practices for the
created of strong passwords.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This guideline applies to employees, contractors, consultants, temporary
and other workers, including all personnel affiliated with third
parties. This guideline applies to all passwords including but not
limited to user-level accounts, system-level accounts, web accounts,
e-mail accounts, screen saver protection, voicemail, and local router
logins.`}</p>
    <h2>{`Statement of Guidelines`}</h2>
    <p>{`Strong passwords are long, the more characters you have the stronger the
password. We recommend a minimum of 14 characters in your password. In
addition, we highly encourage the use of passphrases, passwords made up
of multiple words. Examples include ”`}<em parentName="p">{`It’s time for vacation`}</em>{`” or
”`}<em parentName="p">{`block-curious-sunny-leaves`}</em>{`”. Passphrases are both easy to remember
and type, yet meet the strength requirements. Poor, or weak, passwords
have the following characteristics:`}</p>
    <ul>
      <li parentName="ul">{`Contain eight characters or less.`}</li>
      <li parentName="ul">{`Contain personal information such as birthdates, addresses, phone
numbers, or names of family members, pets, friends, and fantasy
characters.`}</li>
      <li parentName="ul">{`Contain number patterns such as `}<inlineCode parentName="li">{`aaabbb`}</inlineCode>{`, `}<inlineCode parentName="li">{`qwerty`}</inlineCode>{`, `}<inlineCode parentName="li">{`zyxwvuts`}</inlineCode>{`, or `}<inlineCode parentName="li">{`123321`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Are some version of `}<inlineCode parentName="li">{`"Welcome123"`}</inlineCode>{` `}<inlineCode parentName="li">{`"Password123"`}</inlineCode>{` `}<inlineCode parentName="li">{`"Changeme123"`}</inlineCode></li>
    </ul>
    <p>{`In addition, every work account should have a different, unique
password. To enable users to maintain multiple passwords, we highly
encourage the use of ‘password manager’ software that is authorized and
provided by the organization. Whenever possible, also enable the use of
multi-factor authentication.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      